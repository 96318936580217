import Header from 'components/Header'
import CtaButton from 'components/cta-button'
import Image from 'next/image'
import React from 'react'
import css from './easy-overview.module.scss'

import laptop from 'public/laptop.png'

const points = [
  'Build a lead list by utilizing more than 20 Quick Lists or applying any combination of over 150 filters to discover the ideal situation that’s right for you, whether you’re seeking vacant properties with high equity and the owner lives out-of-state, or senior homeowners in oversized properties. Our database is refreshed daily to ensure the highest data quality is readily available when you need it most.',
  'Connect with property owners through our precision skip tracing service, featuring the industry’s most reliable multi-sourced owner contact details. Instantly access both mobile and landline phone numbers, as well as email addresses, thanks to our daily updated, pre-linked database, saving you the effort of laborious data compilation.',
  'Research to make informed decisions, find new opportunities, and stay vigilant to potential risks by accessing the most comprehensive data in one place. We ensure you have a complete arsenal of information at your disposal to assess both the current and potential value of a property.',
]

const EasyOverview = () => (
  <div className={css.easyOverview}>
    <div className={css.top}>
      <Image src={laptop} className={css.laptop} alt="Laptop" unoptimized />
      <div className={css.content}>
        <Header value="It's As Easy As 1, 2, 3" className={css.header} />
        <div className={css.points}>
          {points.map((description, index) => (
            <div className={css.point} key={description}>
              <div className={css.index}>{index + 1}</div>
              <div className={css.description}>{description}</div>
            </div>
          ))}
        </div>
        <CtaButton type="black" label="Try For Free" />
      </div>
    </div>
  </div>
)

export default EasyOverview
