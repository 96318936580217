import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import css from './section-title.module.scss'

/**
 * Standard black/blue section title used on various screens.
 *
 * Standardized styles for span tags used in children.
 */
const SectionTitle: FC<{
  children: ReactNode
  centered?: boolean
  primary?: boolean
  className?: string
}> = ({
  children,
  centered = false,
  primary = false,
  className,
}) => {
  const Tag = primary ? 'h1' : 'h2'

  return (
    <Tag className={classNames(css.sectionTitle, { [css.centered]: centered }, className)}>
      {children}
    </Tag>
  )
}

export default SectionTitle
