import CtaButton from 'components/cta-button'
import Image from 'next/image'
import mobile from 'public/signup/phone-hand.png'
import React, { FC } from 'react'
import css from './driving-for-dollars.module.scss'

const points = [
  ['MLS Marvels', 'Dive into an extensive array of MLS listings, providing you with a comprehensive view of available properties in your desired locations. From cozy condos to sprawling estates, this app has it all.'],
  ['Off-Market Mysteries', 'Unlock exclusive access to off-market properties, hidden gems that aren\'t publicly listed. Feel like a real estate detective as you explore unique opportunities that other might miss.'],
  ['Location, Location, Location', 'Effortlessly navigate through neighborhoods, explore local amenities, and get a feel for the community - all within the app. Location scouting has never been this easy!'],
]

const DrivingForDollars: FC = () => (
  <div className={css.frame}>
    <div className={css.inner}>
      <div className={css.content}>
        <div className={css.header}>
          <div>Driving for</div>
          <div>Dollars</div>
        </div>
        <div className={css.subHeader}>No Separate App Necessary</div>
        <div className={css.body}>
          <div>
            Don&apos;t let valuable opportunities pass you by while you&apos;re on the move.
            PropertyReach is mobile-friendly, enabling you to find properties as you drive
            for dollars. Whether you&apos;re exploring new neighborhoods or attending
            property viewings, our platform ensures you&apos;re always connected and ready
            to seize opportunities.
          </div>
          <br />
          <div className={css.points}>
            {points.map(([title, description]) => (
              <div key={title}>
                <b>{title}</b> {description}
              </div>
            ))}
          </div>
        </div>
        <CtaButton type="blue" label="Sign Up Today" />
      </div>
      <Image src={mobile} className={css.mobile} alt="Phone in hand" style={{ height: 'auto' }} unoptimized />
    </div>
  </div>
)

export default DrivingForDollars
