import Header from 'components/Header'
import classNames from 'classnames'
import Link from 'components/Link'
import React, { FC, ReactNode, useState } from 'react'
import { FaChevronUp } from 'react-icons/fa'
import { IRegionPath } from 'store'
import css from './search-section.module.scss'

const SearchSection: FC<{
  children?: ReactNode
  description?: string
  header: string | string[]
  items?: IRegionPath[]
  splitByLetter?: boolean
}> = ({
  children,
  description,
  header,
  items = [],
  splitByLetter = false
}) => {
  const sections = items.reduce((sections, item) => {
    const label = splitByLetter ? item.name.substring(0, 1) : ''
    let section = sections[sections.length - 1]
    if (section?.label !== label) {
      section = { label, items: [] }
      sections.push(section)
    }

    section.items.push(item)

    return sections
  }, [] as { label: string, items: IRegionPath[] }[])

  const [openSections, setOpenSections] = useState<Record<string, boolean>>({})

  return (
    <div className={classNames(css.searchSection, { [css.split]: !splitByLetter && sections.length > 0 })}>
      <div className={css.content}>
        <Header value={header} subHeader={description} className={css.header} />
        {children}
        {sections.length > 0 && sections.map(({ label, items }) => (
          <div className={classNames(css.section, { [css.closed]: splitByLetter && !(openSections[label] ?? false) })} key={label}>
            {label !== '' && (
              <div
                className={css.label}
                onClick={() => setOpenSections({ ...openSections, [label]: !(openSections[label] ?? false) })}
              >
                <div>{label}</div>
                <FaChevronUp />
              </div>
            )}
            <div className={css.items}>
              {items.map(({ name, path }) => <Link href={path} key={path} className={css.item}>{name}</Link>)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SearchSection
