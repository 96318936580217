import React, { FC } from 'react'
import css from './data-metrics.module.scss'

const metrics = [
  {
    name: 'Properties',
    count: '158,335,701',
  }, {
    name: 'Deeds / Sales',
    count: '365,510,590',
  }, {
    name: 'Pre-Foreclosures',
    count: '19,627,582',
  }, {
    name: 'MLS Records',
    count: '226,018,717',
  }, {
    name: 'Phones & Emails',
    count: '1+ Billion',
  }, {
    name: 'People',
    count: '223,595,344',
  }, {
    name: 'Mortgages',
    count: '303,450,754',
  }, {
    name: 'Involuntary Liens',
    count: '52,916,095',
  }, {
    name: 'Permit Records',
    count: '339,333,860',
  }, {
    name: 'Document Images',
    count: '8+ Billion',
  },
]

const DataMetrics: FC = () => (
  <div className={css.dataMetrics}>
    <div className={css.content}>
      <div className={css.title}>Nationwide Coverage</div>
      <div className={css.metrics}>
        {metrics.map(({ name, count }) => (
          <div className={css.metric} key={name}>
            <div className={css.title}>{count}</div>
            <div className={css.name}>{name}</div>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default DataMetrics
